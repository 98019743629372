import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { paths } from '../../paths';

interface IssueListProps {
  topics: { id: string; name: string }[];
  title?: string;
}

const IssueList = (props: IssueListProps) => {
  const { topics, title } = props;

  return topics.length > 0 ? (
    <Card className={'mb-3'}>
      <CardHeader>{title || 'Popular Issues'}</CardHeader>
      <CardBody>
        <ul className={'pl-3'}>
          {topics.slice(0, 10).map((t) => (
            <li key={t.id}>
              <Link to={paths.VIEW_ISSUE.replace(':id', t.id)}>{t.name}</Link>
            </li>
          ))}
        </ul>
        <h6 className={'ml-0'}>
          <i>
            <Link to={paths.LIST_ISSUES} title={'View list of all issues'}>
              View all issues...
            </Link>
          </i>
        </h6>
      </CardBody>
    </Card>
  ) : null;
};

export default IssueList;
