import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavItem, NavLink } from 'reactstrap';

import { allCategories, AuthUser } from 'tcf-shared/models';

import { paths } from '../../paths';
import config from '../../config';

const upstreamEnvironmentPrefix = {
  prod: '',
  test: 'test-',
  ci: 'ci-',
  dev: 'ci-',
};

interface MainMenuProps {
  authUser?: AuthUser;
}

const MainMenu = (props: MainMenuProps) => {
  const { pathname } = useLocation();
  const { authUser } = props;

  const categoryParam = paths.VIEW_CATEGORY.split('/').pop();
  allCategories.forEach((c: any) => {
    c.url = paths.VIEW_CATEGORY.replace(categoryParam!, c.urlSlug);
  });

  return (
    <Navbar style={{ backgroundColor: '#607194' }} className="py-0">
      {authUser?.isPremiumSubscriber ? (
        <Nav style={{ width: '100%' }} className="square-buttons" pills>
          <NavItem key="home">
            <NavLink
              tag={Link}
              title="View articles"
              to="/"
              disabled={pathname === paths.HOME}
              className={'nav-link' + (pathname === paths.HOME ? ' active' : '')}
            >
              Home
            </NavLink>
          </NavItem>

          {allCategories.map((c: any) => (
            <NavItem key={c.id}>
              {!authUser || !authUser.categoryIds || authUser.categoryIds.includes(c.id) ? (
                <NavLink
                  tag={Link}
                  title={`View ${c.name} articles`}
                  to={c.url}
                  disabled={pathname === c.url}
                  className={'nav-link' + (pathname === c.url ? ' active' : '')}
                >
                  {c.name}
                </NavLink>
              ) : (
                <span
                  title="Your subscription does not include access to this product"
                  className="nav-link text-dark"
                  style={{ cursor: 'not-allowed' }}
                >
                  {c.name}
                </span>
              )}
            </NavItem>
          ))}

          <NavItem key="upstream" className="ml-sm-auto">
            <NavLink
              href={`https://${upstreamEnvironmentPrefix[config.TCF_ENV]}upstream.thecapitolforum.com`}
              target="_blank"
              rel="noopener noreferrer"
              title="Visit TCF Upstream data platform"
            >
              Upstream
            </NavLink>
          </NavItem>

          {/*{config.TCF_ENV !== 'prod' && authUser?.canViewDecisionMakers ? (*/}
          {/*  <NavItem>*/}
          {/*    <Link*/}
          {/*      title="View decision makers"*/}
          {/*      to={paths.LIST_DECISION_MAKERS}*/}
          {/*      className={'nav-link' + (pathname === paths.LIST_DECISION_MAKERS ? ' active' : '')}*/}
          {/*    >*/}
          {/*      Decision Makers*/}
          {/*    </Link>*/}
          {/*  </NavItem>*/}
          {/*) : null}*/}
        </Nav>
      ) : (
        <div style={{ padding: '0.25rem' }}>&nbsp;</div>
      )}
    </Navbar>
  );
};

export default MainMenu;
