import React from 'react';
import { ButtonGroup, Button, Input } from 'reactstrap';

import { Query } from 'tcf-shared/models';

import { PageData } from '../../../components';

interface DocumentListPaginatorProps {
  itemCount: number;
  query: any;
  showPerPage?: boolean;
  onPageChange: (q: Query) => any;
  small?: boolean;
  buttonGroupClassName?: string;
}

const DocumentListPaginator = (props: DocumentListPaginatorProps) => {
  const { itemCount, query, showPerPage, onPageChange, small, buttonGroupClassName } = props;
  const pages = query ? new PageData(query, itemCount, small) : null;

  return showPerPage || (pages && pages.total > 1) ? (
    <>
      {itemCount > 0 && showPerPage && (
        <span className={'float-right ml-2'}>
          Show&nbsp;
          <Input
            type="select"
            name="itemsPerPage"
            id="itemsPerPage"
            value={String(query.limit)}
            style={{ display: 'inline-block', width: 'auto', marginBottom: '0.5rem' }}
            onChange={(evt: any) =>
              onPageChange({
                ...query,
                skip: 0,
                limit: parseInt(evt.target.value, 10),
              })
            }
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="250">250</option>
          </Input>
          &nbsp;per page
        </span>
      )}
      {pages && pages.total > 1 ? (
        <ButtonGroup size="sm" className={buttonGroupClassName ?? ''}>
          {pages.range[0] > 1 && (
            <Button color="light" active={pages.current === 1} onClick={() => onPageChange({ ...query, skip: 0 })}>
              1
            </Button>
          )}
          {pages.range[0] > 1 && (
            <Button disabled color="light">
              ...
            </Button>
          )}
          {pages.range.map((pageNumber: any) => {
            return (
              <Button
                color="light"
                key={pageNumber}
                active={pages.current === pageNumber}
                onClick={() => onPageChange({ ...query, skip: (pageNumber - 1) * query.limit })}
              >
                {pageNumber}
              </Button>
            );
          })}
          {pages.range[pages.range.length - 1] < pages.total && (
            <Button disabled color="light">
              ...
            </Button>
          )}
          {pages.range[pages.range.length - 1] < pages.total && (
            <Button
              color="light"
              active={pages.current === pages.total}
              onClick={() => onPageChange({ ...query, skip: (pages.total - 1) * query.limit })}
            >
              {pages.total}
            </Button>
          )}
        </ButtonGroup>
      ) : null}
    </>
  ) : null;
};

export default DocumentListPaginator;
