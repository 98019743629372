import { AxiosInstance } from 'axios';

import { ApiResponse, DeleteRequest, Query } from 'tcf-shared/models';
import { isDate } from 'lodash';

export class CrudApiClient {
  constructor(readonly axios: AxiosInstance, readonly baseUrl: string) {}

  async search(query: Query): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${this.baseUrl}/search`, query);
  }

  async post(data: {}): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(this.baseUrl, data);
  }

  async postWithFile(data: {}, file?: File): Promise<ApiResponse> {
    const formData = new FormData();
    formData.append('post', JSON.stringify(data));
    if (file) {
      formData.append('file', file);
    }
    return this.axios.post<ApiResponse>(this.baseUrl, formData);
  }

  async get(id: string): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${this.baseUrl}/${id}`);
  }

  async getRelated(id: string): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${this.baseUrl}/${id}/related`);
  }

  async put(id: string, data: {}): Promise<ApiResponse> {
    return this.axios.put<ApiResponse>(`${this.baseUrl}/${id}`, data);
  }

  async patch(id: string, updatedAt: Date | string, patch: {}): Promise<ApiResponse> {
    return this.axios.patch<ApiResponse>(`${this.baseUrl}/${id}`, {
      updatedAt: isDate(updatedAt) ? updatedAt.toISOString() : updatedAt,
      patch,
    });
  }

  async delete(request: DeleteRequest): Promise<ApiResponse> {
    const { id, updatedAt } = request;
    const url =
      `${this.baseUrl}/${id}` + this.getQueryString({ updatedAt: isDate(updatedAt) ? updatedAt.toISOString() : updatedAt });
    return this.axios.delete(url);
  }

  async getDownloadToken(id: string) {
    const url = `${this.baseUrl}/${id}/token`;
    return this.axios.post<ApiResponse>(url);
  }

  protected getQueryString(params: { [key: string]: any }) {
    if (!params) {
      return '';
    }

    let q = '?';
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        if (q.length > 1) {
          q += '&';
        }
        q += key + '=' + params[key];
      }
    });

    return q.length === 1 ? '' : q;
  }
}
