import React, { useState } from 'react';
import { Col, FormGroup, Input, Label, Row, Button } from 'reactstrap';

import Accordion from '../Accordion';

interface FacetFilterProps {
  title: string;
  options: { value: string; label: string; disabled?: boolean; selected?: boolean }[];
  minOptionsToShow?: number;
  onChange?: any;
}

const MIN_OPTIONS = 5;
const MAX_OPTIONS = 25;

const FacetFilter = (props: FacetFilterProps) => {
  const { onChange, title, options, minOptionsToShow } = props;
  const initialOptionsCount = minOptionsToShow || MIN_OPTIONS;
  const [numberOptions, setNumberOptions] = useState(initialOptionsCount);

  const [isOpen, setIsOpen] = useState(true);
  const onToggle = () => setIsOpen(!isOpen);
  const viewMoreOptions = () => setNumberOptions((prevstate: number) => Math.min(MAX_OPTIONS, prevstate + initialOptionsCount));
  const resetNumberOptions = () => setNumberOptions(initialOptionsCount);

  return (
    <Accordion id={`accordion${title}`} title={title} isOpen={isOpen} handleToggle={onToggle} className={'mb-3'}>
      {options.slice(0, numberOptions).map((o) => (
        <FormGroup check key={`checkbox${title}${o.value}`}>
          <Label check className={o.disabled ? 'text-muted' : ''} title={o.disabled ? 'Not included in your subscription' : ''}>
            <Input type="checkbox" value={o.value} disabled={o.disabled} checked={o.selected} onChange={onChange} />
            &nbsp;{o.label}
          </Label>
        </FormGroup>
      ))}
      {((numberOptions < MAX_OPTIONS && numberOptions < options.length) || numberOptions > initialOptionsCount) && (
        <i>
          <Row>
            <Col xs={1}>&nbsp;</Col>
            {numberOptions < MAX_OPTIONS && numberOptions < options.length && (
              <Col xs={5}>
                <Button size="sm" title={'View more options'} onClick={viewMoreOptions} className="mr-4">
                  More...
                </Button>
              </Col>
            )}
            {numberOptions > initialOptionsCount && (
              <Col xs={5}>
                <Button size="sm" title={'View fewer options'} onClick={resetNumberOptions}>
                  Fewer...
                </Button>
              </Col>
            )}
            <Col xs={1}>&nbsp;</Col>
          </Row>
        </i>
      )}
    </Accordion>
  );
};

export default FacetFilter;
