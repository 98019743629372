import React, { useEffect, useState } from 'react';
import Loader from 'react-loader';
import { Badge, Container } from 'reactstrap';
import { useLocation } from 'react-router-dom';

import { generateTCFId } from 'tcf-shared/functions';

import 'react-widgets/styles.css';
import './App.css';

import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { getAuthError, getAuthUser } from '../../reducers/authReducer';
import { leavePage, visitPage } from '../../actions/usageActions';
import { getTopDocumentsAndTopics } from '../../actions/docViewsActions';
import { TOP_DOCS_AND_TOPICS_STORE } from '../../storeIds';
import config from '../../config';
import Footer from '../Header/Footer';
import Header from '../Header/Header';
import Routes from './Routes';

const App = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const [headerHeight, setHeaderHeight] = useState(0);

  const authUser = useAppSelector((state) => getAuthUser(state));
  const authError = useAppSelector((state) => getAuthError(state));
  const isFetching = useAppSelector((state) => state.auth.isLoggingIn);

  const authUserId = authUser?.id;
  const isLoggedIn = !!authUser;

  useEffect(() => {
    if (isLoggedIn) {
      const visitId = generateTCFId();
      const handleBeforeUnload = () => {
        if (navigator.sendBeacon && document.visibilityState === 'hidden') {
          navigator.sendBeacon(`/api/usage/${visitId}`);
        }
      };
      dispatch(visitPage(visitId, pathname));
      window.addEventListener('visibilitychange', handleBeforeUnload);
      return () => {
        window.removeEventListener('visibilitychange', handleBeforeUnload);
        dispatch(leavePage(visitId, pathname));
      };
    }
  }, [dispatch, pathname, isLoggedIn]);

  useEffect(() => {
    if (authUserId) {
      dispatch(getTopDocumentsAndTopics(TOP_DOCS_AND_TOPICS_STORE));
    }
  }, [dispatch, authUserId]);

  return (
    <Loader loaded={!isFetching}>
      <div className="app">
        <Header onHeightChange={setHeaderHeight} />
        <Container style={{ position: 'relative', marginTop: headerHeight, minHeight: '100vh' }} fluid>
          <Routes authUser={authUser} authError={authError} headerHeight={headerHeight} />
        </Container>
        <Footer />
      </div>
      <div id="copyright">
        <br />
        <br />
        <br />
        <br />
        <br />
        <p>
          This content is copyrighted, intended to be viewed online, and may not be printed. Please contact your account manager
          or email us at editorial@thecapitolforum.com if you have any questions about this.
        </p>
      </div>
      {config.TCF_ENV !== 'prod' && (
        <Badge color="warning" style={{ position: 'fixed', top: 5, left: 5, zIndex: 2000 }}>
          Environment: {config.TCF_ENV}
        </Badge>
      )}
    </Loader>
  );
};

export default App;
