import React from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';

import {
  allCategoriesTableFilterOptions,
  allRegionsTableFilterOptions,
  allSectorsTableFilterOptions,
  Query,
  Topic,
  UserSearch,
} from 'tcf-shared/models';

import { paths } from '../../../paths';
import { getAuthUser } from '../../../reducers/authReducer';
import { useAppSelector } from '../../../utils/hooks';
import { formatVeryShortDate } from '../../../utils/momentFormat';
import TcfBootstrapSearchTable, {
  getDefaultSorted,
  getPagingParamsForTable,
  TableState,
} from '../../../components/TcfBootstrapSearchTable';
import ErrorComponent from '../../AsyncPage/ErrorComponent';
import { Icon } from '../../../components';

export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_SORT_BY = 'name';
export const DEFAULT_SORT_ORDER = 'asc';

interface IssueListProps {
  manageIssues?: boolean;
  storeId: string;
  query: Query;
  onQueryChange: (query: Query, debounce?: boolean) => void;
}

const IssueList = (props: IssueListProps) => {
  const { manageIssues, storeId, query, onQueryChange } = props;

  const authUser = useAppSelector((s) => getAuthUser(s));
  const isAdmin = authUser?.isAdmin;
  const isStaff = authUser?.isStaff;

  const serverStore = useAppSelector((state) => state.serverStores?.[storeId]);
  const payload = serverStore?.payload;
  const error = serverStore?.error;
  const isFetching = serverStore?.isFetching ?? true;
  const isDeleting = serverStore?.isDeleting;

  if (error) return <ErrorComponent error={error} />;
  if (isFetching || isDeleting) return <Loader loaded={false} />;

  const { currentPage, limit, totalSize, sortBy, sortOrder } = getPagingParamsForTable(
    payload,
    query,
    DEFAULT_PAGE_SIZE,
    DEFAULT_SORT_BY,
    DEFAULT_SORT_ORDER,
  );

  const onTableChange = (type: unknown, newState: TableState) => {
    const { page, sizePerPage, sortField, sortOrder: _sortOrder } = newState;
    const _search: UserSearch = { ...query?.search };
    const _sortBy = sortField || query?.search?.sortBy || DEFAULT_SORT_BY;
    const _finalSortOrder = _sortOrder || query?.search?.sortOrder || DEFAULT_SORT_ORDER;
    const skip = sizePerPage * (page - 1);
    if (
      query.skip !== skip ||
      query.limit !== sizePerPage ||
      (_sortBy !== _search.sortBy && !(_sortBy === DEFAULT_SORT_BY && _search.sortBy == null)) ||
      (_finalSortOrder !== _search.sortOrder && !(_finalSortOrder === DEFAULT_SORT_ORDER && _search.sortOrder == null))
    ) {
      _search.sortBy = _sortBy;
      _search.sortOrder = _finalSortOrder;
      onQueryChange({ ...query, skip, limit: sizePerPage, search: _search });
    }
  };

  const actionsFormatter = (cell: string) => {
    return (
      <Link to={paths.admin.EDIT_ISSUE.replace(':id', cell)} title={'Edit issue'}>
        Edit
      </Link>
    );
  };

  const formatName = (cell: string, row: any) => (
    <Link to={paths.VIEW_ISSUE.replace(':id', row.id)} title={'View issue'}>
      {cell}
    </Link>
  );

  const formatSectors = (cell: { code: string }[], row: Topic) =>
    cell?.length ? (
      <small>
        {cell
          .map((cx) => allSectorsTableFilterOptions.find((c) => c.value === cx.code)?.label || '')
          .sort()
          .join('; ')}
      </small>
    ) : null;

  const formatCategories = (cell: { id: string }[]) =>
    cell && cell.length > 0 ? (
      <small>
        {Array.from(
          new Set(cell.map((cx) => allCategoriesTableFilterOptions.find((c) => c.value === cx.id)?.label || '')),
        ).join('; ')}
      </small>
    ) : null;

  const formatRegions = (cell: { id: string }[]) =>
    cell && cell.length > 0 ? (
      <small>{cell.map((cx) => allRegionsTableFilterOptions.find((c) => c.value === cx.id)?.label || '').join('; ')}</small>
    ) : null;

  const formatCompanies = (cell: { id: string; name: string }[]) =>
    cell && cell.length > 0 ? <small>{cell.map((cx) => cx.name).join('; ')}</small> : null;

  const check = <Icon name={'check'} size={20} className={'text-success'} />;
  const formatBoolean = (cell: boolean) => (cell ? check : null);

  const formatCreatedAt = (cell: any, row: Topic) => formatVeryShortDate(row.meta.createdAt);

  let columns: any[] = [];
  if (manageIssues && isAdmin) {
    columns.push({
      // isDummyField: true,
      dataField: 'id',
      text: 'Actions',
      formatter: actionsFormatter,
      headerStyle: { width: '7%' },
      align: 'left',
      headerAlign: 'left',
    });
  }

  columns = columns.concat([
    {
      dataField: 'name',
      text: 'Issue',
      formatter: formatName,
      sort: true,
      headerStyle: { width: '20%' },
    },
    {
      dataField: 'companies',
      text: 'Companies',
      formatter: formatCompanies,
      headerStyle: { width: '15%' },
    },
    {
      dataField: 'categories',
      text: 'Categories',
      formatter: formatCategories,
      sort: manageIssues,
      headerStyle: { width: '15%' },
    },
    {
      dataField: 'regions',
      text: 'Regions',
      formatter: formatRegions,
      sort: manageIssues,
      headerStyle: { width: '13%' },
    },
    {
      dataField: 'sectors',
      text: 'Sectors',
      formatter: formatSectors,
      sort: manageIssues,
      headerStyle: { width: '15%' },
    },
    {
      dataField: 'current',
      text: 'Current?',
      formatter: formatBoolean,
      align: 'center',
      headerAlign: 'center',
      sort: true,
      headerStyle: { width: '6%' },
    },
  ]);

  if (manageIssues && (isStaff || isAdmin)) {
    columns.push({
      dataField: 'efficyKey',
      text: 'Efficy ID',
      align: 'center',
      sort: true,
      headerStyle: { width: '7%' },
    });
    columns.push({
      dataField: 'createdAt',
      text: 'Added',
      formatter: formatCreatedAt,
      align: 'right',
      sort: true,
      headerAlign: 'right',
      headerStyle: { width: '9%' },
    });
  }

  return !payload?.total ? (
    <p>No matching issues found</p>
  ) : (
    <>
      <div>{totalSize?.toLocaleString() || 0} Issues Found</div>
      <TcfBootstrapSearchTable
        allowPaging
        allowWrap
        columns={columns}
        dataSource={payload?.results || []}
        defaultSorted={getDefaultSorted(sortBy, sortOrder)}
        keyField="id"
        onTableChange={onTableChange}
        page={currentPage}
        remote
        sizePerPage={limit}
        totalSize={totalSize}
      />
    </>
  );
};

export default IssueList;
