import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Table } from 'reactstrap';

import { Company, allSectors, Query, StockExchange, CompanyExchangeTicker } from 'tcf-shared/models';

import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { getCompany, getCompanyRelated } from '../../actions/companiesActions';
import { resetQuery, setQuery } from '../../actions/queryActions';
import { readDocuments } from '../../actions/documentsActions';
import CompanyList from '../../components/SearchSideBar/CompanyList';
import DocumentList from '../Document/components/DocumentList';
import IssueList from '../../components/SearchSideBar/IssueList';
import SectorList from '../../components/SearchSideBar/SectorList';
import AsyncPage from '../AsyncPage/AsyncPage';
import ViewCalendarButton from '../../components/SearchSideBar/ViewCalendarButton';
import { resetServerStore } from '../../actions/serverStoreActions';
import { paths } from '../../paths';

interface CompanyRelated {
  companies: { id: string; name: string }[];
  topics: { id: string; name: string }[];
  sectors: { code: string; name: string }[];
  acquiredByName?: string;
  stockExchanges?: StockExchange[];
}

const ARTICLES_STORE = 'viewCompanyArticlesStore';
const ARTICLES_QUERY = 'viewCompanyArticlesQuery';
const COMPANY_STORE = 'viewCompanyStore';
const COMPANY_RELATED_STORE = 'viewCompanyRelatedStore';

const ViewCompany = () => {
  const dispatch = useAppDispatch();
  const { pathname, state }: any = useLocation();
  const history = useHistory();
  const stateString = JSON.stringify(state || {});

  const company: Company = useAppSelector((s) => s.serverStores?.[COMPANY_STORE]?.payload);
  const companyRelated: CompanyRelated = useAppSelector((s) => s.serverStores?.[COMPANY_RELATED_STORE]?.payload?.results);
  const articles = useAppSelector((s) => s.serverStores?.[ARTICLES_STORE]?.payload);
  const error: string = useAppSelector(
    (s) => s.serverStores?.[COMPANY_STORE]?.error || s.serverStores?.[COMPANY_RELATED_STORE]?.error,
  );
  const isFetching: boolean = useAppSelector(
    (s) => s.serverStores?.[COMPANY_STORE]?.isFetching || s.serverStores?.[COMPANY_RELATED_STORE]?.isFetching,
  );

  const stockExchanges = companyRelated?.stockExchanges || [];
  const micLookup: { [mic: string]: StockExchange } = stockExchanges.reduce((micDict: any, mi: StockExchange) => {
    micDict[mi.mic] = mi;
    return micDict;
  }, {});

  const acquiredByName = companyRelated?.acquiredByName || null;

  const [delayRender, setDelayRender] = useState(true);
  const { id: urlId } = useParams<{ id: string }>();

  const setupQuery = useCallback(
    (q: Query) => {
      q.skip = q.skip || 0;
      q.limit = q.limit || 10;
      q.search = { companies: [urlId] };
    },
    [urlId],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCompany(COMPANY_STORE, urlId!));
    dispatch(getCompanyRelated(COMPANY_RELATED_STORE, urlId!));

    const q = {};
    setupQuery(q);
    dispatch(readDocuments(ARTICLES_STORE, q));
    dispatch(setQuery(ARTICLES_QUERY, q));
    setDelayRender(false);
    return () => {
      dispatch(resetQuery(ARTICLES_QUERY));
      dispatch(resetServerStore(ARTICLES_STORE));
      dispatch(resetServerStore(COMPANY_STORE));
      dispatch(resetServerStore(COMPANY_RELATED_STORE));
    };
  }, [urlId, setupQuery, dispatch]);

  useEffect(() => {
    if (stateString.length > 2) {
      window.scrollTo(0, 0);
      const q = JSON.parse(stateString);
      setupQuery(q);
      dispatch(readDocuments(ARTICLES_STORE, q));
      dispatch(setQuery(ARTICLES_QUERY, q));
    }
  }, [stateString, setupQuery, dispatch]);

  const sectors =
    (company?.sectors?.map((s: { code: string }) => allSectors.find((a: any) => a.code === s.code)?.name) || []).join(', ') ||
    '';

  const exchangeTickers = company?.exchangeTickers || [];
  const primaryTickers = exchangeTickers.filter((et) => et.status === 'primary');
  const alternateTickers = exchangeTickers.filter((et) => et.status === 'alternate');
  const delistedTickers = exchangeTickers.filter((et) => et.status === 'delisted');

  const getTickerLabels = (tickers: CompanyExchangeTicker[], areDelisted: boolean) => {
    return (
      tickers?.map((et: CompanyExchangeTicker) => {
        const mic = et?.exchange;
        const stockExchange = mic ? micLookup[mic] : null;
        const isUSMarket = stockExchange?.country === 'USA';
        const exchangeLabel = isUSMarket ? '' : stockExchange?.prefix || mic || '';
        return (
          <div key={`${exchangeLabel ? exchangeLabel : ''}${et.symbol}`}>
            {`${exchangeLabel ? exchangeLabel + ': ' : ''}${et.symbol}${areDelisted ? ' (delisted)' : ''}`}
          </div>
        );
      }) || null
    );
  };

  const primaryTickerLabels = getTickerLabels(primaryTickers, false);
  const alternateTickerLabels = getTickerLabels(alternateTickers, false);
  const delistedTickerLabels = getTickerLabels(delistedTickers, true);

  const aliases = company?.aliases?.length ? company.aliases.map((a: string) => <div key={`alias${a}`}>{a}</div>) : null;

  const acquiredBy = company?.acquiredBy ? (
    <Link to={paths.VIEW_COMPANY.replace(':id', company.acquiredBy)} title={'View Acquirer Company'}>
      {acquiredByName || company.acquiredBy}
    </Link>
  ) : null;

  const companiesToShow: { id: string; name: string }[] = useMemo(
    () => (companyRelated ? companyRelated.companies.sort((a, b) => a.name.localeCompare(b.name)).slice(0, 10) : []),
    [companyRelated],
  );

  const topicsToShow: { id: string; name: string }[] = useMemo(
    () => (companyRelated ? companyRelated.topics.sort((a, b) => a.name.localeCompare(b.name)).slice(0, 10) : []),
    [companyRelated],
  );

  const sectorsToShow: { code: string; name: string }[] = useMemo(
    () => (companyRelated ? companyRelated.sectors.sort((a, b) => a.name.localeCompare(b.name)).slice(0, 10) : []),
    [companyRelated],
  );

  const onQueryChange = (q: Query) => {
    setupQuery(q);
    history.push(pathname, q);
  };

  const totalArticles = articles?.total;

  return (
    <AsyncPage title={company?.name || 'Company'} loading={isFetching || delayRender} error={error}>
      <Row>
        <Col sm={12} md={8} className={'py-3 px-4 px-md-3'} style={{ backgroundColor: '#fff' }}>
          <div className={'mb-2'}>
            {totalArticles == null ? (
              <span>Retrieving articles</span>
            ) : (
              <span>
                {totalArticles.toLocaleString()}&nbsp;article{totalArticles !== 1 ? 's' : ''}
              </span>
            )}{' '}
            for company <strong>{company?.name || '...'}</strong>
          </div>
          <div>
            <DocumentList queryId={ARTICLES_QUERY} storeId={ARTICLES_STORE} onQueryChange={onQueryChange} />
          </div>
        </Col>
        <Col md={4} className={'sidebar pt-3 pl-4 pr-4 pl-md-3 pr-md-3'}>
          <ViewCalendarButton />
          <Table borderless>
            <tbody>
              <tr>
                <th style={{ padding: 0 }}>Name:</th>
                <td style={{ padding: 0, paddingBottom: '0.75rem' }}>{company?.name || ''}</td>
              </tr>
              {aliases ? (
                <tr>
                  <th style={{ padding: 0 }}>Aliases:</th>
                  <td style={{ padding: 0, paddingBottom: '0.75rem' }}>{aliases}</td>
                </tr>
              ) : null}
              {primaryTickerLabels || alternateTickerLabels || delistedTickerLabels ? (
                <tr className="mb-3">
                  <th style={{ padding: 0 }}>Ticker(s):</th>
                  <td style={{ padding: 0, paddingBottom: '0.75rem' }}>
                    {primaryTickerLabels}
                    {alternateTickerLabels}
                    {delistedTickerLabels}
                  </td>
                </tr>
              ) : null}
              {sectors ? (
                <tr>
                  <th style={{ padding: 0 }}>Sectors:</th>
                  <td style={{ padding: 0, paddingBottom: '0.75rem' }}>{sectors}</td>
                </tr>
              ) : null}
              {acquiredBy ? (
                <tr>
                  <th style={{ padding: 0 }}>Acquired By:</th>
                  <td style={{ padding: 0, paddingBottom: '0.75rem' }}>{acquiredBy}</td>
                </tr>
              ) : null}
            </tbody>
          </Table>
          {topicsToShow.length > 0 ? <IssueList topics={topicsToShow} title={'Related Issues'} /> : null}
          {companiesToShow.length > 0 ? <CompanyList companies={companiesToShow} title={'Related Companies'} /> : null}
          {sectorsToShow.length > 0 ? <SectorList sectors={sectorsToShow} title={'Related Sectors'} /> : null}
        </Col>
      </Row>
    </AsyncPage>
  );
};

export default ViewCompany;
