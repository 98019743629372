export const paths = {
  HOME: '/',

  LOGIN: '/auth/login',
  LOGIN_WITH_TOKEN: '/auth/login/token',
  LOGOUT: '/auth/logout',
  VIEW_ACCOUNT: '/account',
  VIEW_SUBSCRIPTION: '/subscription',

  SELF_MANAGE_ORGANIZATION: '/organization-self-manage',

  EDIT_SEARCH: '/searches/:userId/edit/:id',
  SAVED_SEARCH_RESULTS: '/searches/:id/results',

  VIEW_DOCUMENT: '/docs/:id',

  VIEW_CATEGORY: '/categories/:id(mergers|investigations|antitrust)',

  LIST_COMPANIES: '/companies',
  VIEW_COMPANY: '/companies/:id',

  VIEW_SECTOR: '/sectors/:id',

  VIEW_REGION: '/regions/:id',

  LIST_ISSUES: '/issues',
  VIEW_ISSUE: '/issues/:id',

  LIST_DECISION_MAKERS: '/directory/decision-makers',
  LIST_DIRECTORY_ENTITY: '/directory/entity',
  VIEW_DIRECTORY_ENTITY: '/directory/entity/:id',

  VIEW_CALENDAR: '/calendar',

  NOTIFICATIONS: '/notifications',

  // Deprecated paths:
  VIEW_INDUSTRY: '/industries/:id',
  TOPICS_LIST: '/topics',
  VIEW_TOPIC: '/topics/:id',

  admin: {
    ROOT: '/admin',

    MANAGE_DOCUMENTS: '/admin/docs/:doc_type(article|source|transcript|digest|announcement|draft)?',
    EDIT_DOCUMENT: '/admin/docs/edit/:id',
    VIEW_DOCUMENT_ACTIVITY: '/admin/docs/activity/:id',

    MANAGE_COMPANIES: '/admin/companies',
    EDIT_COMPANY: '/admin/companies/edit/:id',

    MANAGE_TOPICS: '/admin/topics',
    EDIT_TOPIC: '/admin/topics/edit/:id',
    MANAGE_ISSUES: '/admin/issues',
    EDIT_ISSUE: '/admin/issues/edit/:id',

    EDIT_DIRECTORY_ENTITY: '/admin/directory/entity/:id',
    MANAGE_DIRECTORY: '/admin/directory/manage',

    MANAGE_ORGANIZATIONS: '/admin/organizations',
    EDIT_ORGANIZATION: '/admin/organizations/edit/:id',
    VIEW_ORGANIZATION: '/admin/organizations/view/:id',

    MANAGE_USERS: '/admin/users',
    EDIT_USER: '/admin/users/edit/:id',
    VIEW_USER: '/admin/users/view/:id',
    BULK_ADD_USERS: '/admin/users/bulk/add/:orgId?',

    MANAGE_CALENDAR: '/admin/calendar',
    EDIT_CALENDAR_EVENT: '/admin/calendar/edit/:id',
    VIEW_ANTITRUST_AGENDA: '/admin/calendar/agenda',

    REPORTS: '/admin/activity/report',
    VIEW_ALL_ACTIVITY: '/admin/activity',

    MANAGE_EMAIL_SUPPRESSIONS: '/admin/email-suppressions',
  },
};
