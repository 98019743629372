import React from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';

import { DocumentSearch, Query } from 'tcf-shared/models';

import { formatLongDate } from '../../../utils/momentFormat';
import { paths } from '../../../paths';
import { useAppSelector } from '../../../utils/hooks';
import DocumentListPaginator from './DocumentListPaginator';
import DocumentSearchDescription from './DocumentSearchDescription';
import ErrorComponent from '../../AsyncPage/ErrorComponent';

interface DocumentPreviewListProps {
  query?: Query;
  queryId?: string;
  storeId: string;
  showPerPage?: boolean;
  hideSummary?: boolean;
  showSort?: boolean;
  onQueryChange: any;
  includeIssuesStoreId?: string;
  excludeIssuesStoreId?: string;
}

const DocumentPreviewList = (props: DocumentPreviewListProps) => {
  const { onQueryChange, storeId, showPerPage, hideSummary, includeIssuesStoreId, excludeIssuesStoreId } = props;

  const query: Query = useAppSelector((s) => (props.queryId ? s.queries[props.queryId] : props.query));

  const documentSearch: DocumentSearch = { ...query?.search };
  const documentStore = useAppSelector((s) => s.serverStores?.[storeId]);
  const error: string = documentStore?.error;
  const isFetching: boolean = documentStore?.isFetching;
  const payload = documentStore?.payload;

  const itemStyle = { marginBottom: '1rem' };
  const results = payload?.results || [];
  const itemCount = payload?.total ?? 0;

  const resultsSummary = `${itemCount.toLocaleString()} documents found.`;

  const paginator = (
    <div>
      <div style={itemStyle}>
        <DocumentListPaginator query={query} itemCount={itemCount} showPerPage={showPerPage} onPageChange={onQueryChange} />
      </div>
    </div>
  );

  if (isFetching) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  }

  return (
    <div>
      {resultsSummary && !hideSummary && (
        <p className={'font-italic'}>
          {resultsSummary}&nbsp;&nbsp;
          <DocumentSearchDescription
            documentSearch={documentSearch}
            includeIssuesStoreId={includeIssuesStoreId}
            excludeIssuesStoreId={excludeIssuesStoreId}
          />
        </p>
      )}
      {paginator}
      {results.length === 0 ? (
        <div>No matching documents found.</div>
      ) : (
        <div>
          {results.map((document: any) => (
            <div key={document.id}>
              <div className="tcf-article-date">
                {document.meta?.publishing?.displayPublishedAt
                  ? formatLongDate(document.meta.publishing.displayPublishedAt)
                  : ''}
                {!document.meta?.publishing?.published ? <small>&nbsp;Draft</small> : ''}
              </div>
              <div className="tcf-article-title">
                <Link to={paths.VIEW_DOCUMENT.replace(':id', document.id)} title={'Read entire article'}>
                  {document.title}
                </Link>
              </div>
              {document.attachment?.content?.length ? (
                <div className="tcf-article-text">
                  {document.attachment?.content}&nbsp;
                  <Link to={paths.VIEW_DOCUMENT.replace(':id', document.id)} title={'Read entire article'}>
                    Read more
                  </Link>
                </div>
              ) : null}
              <hr />
            </div>
          ))}
        </div>
      )}
      {itemCount > 0 && paginator}
    </div>
  );
};

export default DocumentPreviewList;
